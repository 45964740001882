
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import { ApiPayment } from "@/core/api";
import {
  refundOverviewData,
  RefundStatus,
} from "@/core/directive/interface/payment";
import { getRefundStatus } from "@/core/directive/function/paymentManagement";
import { formatDate } from "@/core/directive/function/common";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "payment-management-refund-detail",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const { showServerErrorMsg } = mixin();

    const loading = ref(false);

    const baseData = ref(Object.assign({}, _.cloneDeep(refundOverviewData)));

    const getShowInfo = async () => {
      // loading.value = true;
      const { data } = await ApiPayment.showRefund({ id: route.params.id });
      // loading.value = false;
      if (data.code == 0) {
        baseData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getStatusClass = computed(() => {
      return (status) => {
        return getRefundStatus(status);
      };
    });

    const retryRefund = async (id) => {
      loading.value = true;
      const { data } = await ApiPayment.retryRefund({
        id: id,
      });
      loading.value = false;
      if (data.code == 0) {
        getShowInfo();
        Swal.fire({
          text: t("payment.retrySuccessMsg"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const cancelRefund = async (id) => {
      loading.value = true;
      const { data } = await ApiPayment.cancelRefund({
        id: id,
      });
      loading.value = false;
      if (data.code == 0) {
        getShowInfo();
        Swal.fire({
          text: t("payment.refundCancelSuccessMsg"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const onCancelButtonClick = (id) => {
      Swal.fire({
        text: t("payment.refundCancelConfirmMsg"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.confirm"),
        cancelButtonText: t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          cancelRefund(id);
        }
      });
    };

    const backToList = () => {
      router.replace("/payment-management/refund");
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getFromInfo();
    });

    return {
      loading,
      baseData,
      t,
      formatDate,
      getFromInfo,
      getStatusClass,
      backToList,
      retryRefund,
      onCancelButtonClick,
      RefundStatus,
    };
  },
});
